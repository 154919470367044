<template>
<div :key="qnaKey">
  <div class="div_title" id="qna-id">
    <context :context=cate />
    <policies/>
  </div>
  <div v-if="this.api.getCookies('login') === 'true' || this.api.getCookies('login') === true">
    <div class="card main" >
      <Tabs :value="mIdx">
        <TabList>
          <Tab value=0>문의하기</Tab>
          <Tab value=1 @click="procGetQna(this.page)">문의내역</Tab>
        </TabList>
        <TabPanels>
          <TabPanel value=0>
            <DataTable v-model:filters="filters" @filter="onFilter" :value="policyList" :paginator="!directInput" :rows="10" dataKey="IDX" :loading="loading" :rowsPerPageOptions="directInput ? null : [5, 10, 20, 50]"  stripedRows >
              <template #header>
                <IconField style="width:100%;" class="div_title2">
                  <span style="width:100%">
                    <InputIcon>
                      <i class="pi pi-search" />
                    </InputIcon>
                    <InputText v-model="filters['global'].value" placeholder="무엇과 관련된 문의일까요? (ex.수수료,배송기간 등)" size="large" :style="customStyle" @input="onSearchInput" :disabled="directInput"/>
                  </span>
                  <span style="min-width:110px;">
                    <Checkbox v-model="directInput" inputId="directInput" binary @change="onDirectInputChange"/>
                    <label for="directInput" class="margin10">직접문의</label>
                  </span>
                </IconField>
              </template>
              <template #empty >
                <table class="dynamic-table">
                  <tr style="height:100px;">
                    <td style="width:100%;">
                      <table class="order_sub_table" style="table-layout:fixed;">
                        <tbody style="text-align: left;">
                          <tr style="height:30px;">
                            <td class="container_sub_style">
                              <div class="div_center">
                                <div style="min-width:100px;" v-tooltip="'주문을 하신 경우 주문자의 성함, 문의만 하시는 경우에는 연락받는 분의 성함을 입력해 주시면 더 빠르게 답변을 드릴 수 있습니다'">문의자 *</div>
                                <div style="width:100%;">
                                  <input ref="name" class="normalInput" type="text" >
                                </div>
                              </div>
                            </td>
                            <td class="container_sub_style">
                              <div class="div_center">
                                <div style="min-width:100px;" v-tooltip="'문의 내용에 대한 답변을 받을 이메일 주소를 적어주세요'">이메일 *</div>
                                <div style="width:100%;">
                                  <input ref="mail" class="normalInput" type="email" @blur="validate('email', $refs.mail)" :value="this.api.getCookies('mail')">
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr style="height:30px;">
                            <td class="container_sub_style">
                              <div class="div_center">
                                <div class="link" style="min-width:100px;" v-tooltip="'문의 내용에 대한 답변을 받을 폰번호를 적어주세요'">폰번호</div>
                                <div style="width:100%;">
                                  <input ref="phone" class="normalInput" type="tel" @blur="validate('phone', $refs.phone)">
                                </div>
                              </div>
                            </td>
                            <td class="container_sub_style">
                              <div class="div_center">
                                <div style="min-width:100px;" v-tooltip="'주문내역이 없으면 없음을 선택해주세요'">주문내역</div>
                                <div style="width:100%;">
                                  <input ref="order" class="normalInput" type="text" @click="click({type:'showModal'})" readonly>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </table>
                <table style="width:100%;">
                  <tr>
                    <td class="td_title" colspan="4">
                      <textarea style='padding:10px; margin-top:15px;' ref="content" placeholder="견적요청은 대행신청을 해주시면 알려드립니다.&#10;(캐로셀과 vinted 상품은 geek9.kr 첫페이지에서 링크로 견적조회 가능합니다)&#10;&#10;주문한 상품이 있으시면 반드시 주문내역을 선택해주시기 바랍니다.&#10;&#10;주문이 조회되지 않을 때 조회기간을 늘려서 조회하시면 됩니다.&#10;&#10;송장번호로 조회가 안되는 경우는 아직 세관통관이 안되었기 때문입니다." />   
                    </td>
                  </tr>
                  <tr>
                    <label type="button" id="pic" />
                    <label class="attach link" type="button" for="inputFile">파일첨부</label>
                    <label ref="picName" class="attach_label" for="inputFile"></label>
                    <input type="file" id="inputFile" @change="readFile($event)" style="display:none">
                  </tr>
                </table>
                <div class="container_no_login">
                  <p style="color:blue">판매자가 정확하게 언제 보내줄지는 GEEk9에서도 알 수 없기 때문에 아래의 문의에는 답변이 어렵습니다</p>
                  <p style="color:red">"평균적인 배송기간이 얼마나 되나요?"</p>
                  <p style="color:red">"진행상황이 어떻게 되나요?"<br>(홈페이지에서 조회되는게 현재진행상황입니다)</p>
                  <p style="color:red">"언제 물건 받을 수 있나요?"</p>
                  <p style="color:red">"지금 물건 어디쯤 있나요?"</p>
                  <p style="color:red">"판매자가 언제 물건 보내주나요?"</p>
                  <p style="color:red">"언제 배송 시작되나요?"</p>
                  <p><a href="http://geek9.kr/faq?idx=10" target="_blank">관련설명보기</a></p>
                  <p style="color:blue">진행상황이 바뀌지 않아도 GEEK9에서는 계속 판매자와 협의 중인거고, </p>
                  <p style="color:blue">계속 진행상황이 바뀌지 않으면 판매자가 답변이 없는 겁니다. </p>
                  <p style="color:blue">더이상 기다리지 못하시겠으면 환불요청해주시면 됩니다. </p>
                  <p style="color:blue">판매자한테 결제하기 전에 환불요청해주시면 2일 내로 환불됩니다. </p>
                  <p style="color:blue;font-weight: bolder;">카톡이나 문자/전화로는 응대를 하지 않습니다. 모든 문의는 문의 게시판에 남겨주시기 바랍니다.</p>
                </div>
                <br>
                <div class="divider" />
                <Button @click="click({type:'reg'})" label="문의하기" raised size="small" />
              </template>
              <template #loading> <div class="no-result">데이터 검색중...</div> </template>
              <Column :header="filteredCount === 0 ? '문의작성' : '관련답변'" field="content" >
                <template #body="{ data }">
                  <div class="flex items-center gap-2">
                    <span> {{ data.content }}</span>
                  </div>
                </template>
              </Column>
            </DataTable>
          </TabPanel>
          <TabPanel value=1>
            <div id="sub_menu" style="margin-bottom:20px; text-align:center">
              <div v-if="qnaList.length === 0" class="no-result"> 
                문의내역이 없습니다.
              </div>
              <div v-else>
                <DataTable v-model:expandedRows="expandedRows" :value="qnaList" dataKey="IDX" @rowExpand="onRowExpand" stripedRows>
                  <Column expander />
                  <Column field="QNA_DATE" header="문의일" />
                  <Column field="TITLE" header="문의내용" >
                    <template #body="slotProps">
                      <span>{{ slotProps.data.TITLE }}</span>
                      &nbsp;<i v-if="!(slotProps.data.FILE_NAME === undefined || slotProps.data.FILE_NAME === '') || !(slotProps.data.REPLY_FILE_NAME === null || slotProps.data.REPLY_FILE_NAME === undefined || slotProps.data.REPLY_FILE_NAME === '')" class="pi pi-spin pi-inbox" style="font-size: 0.8rem"></i>
                    </template>
                  </Column>
                  <Column field="REPLY_YN" header="답변" />
                  <template #expansion="slotProps" >
                    <qnac @remove="remove" class="content" :idx="slotProps.data.IDX" :content="slotProps.data.CONTENT" :country="slotProps.data.COUNTRY" :mail="slotProps.data.MAIL" :orderList="slotProps.data.ORDER_LIST" :orderName="slotProps.data.ORDER_NAME" :phone="slotProps.data.PHONE" :qnaDate="slotProps.data.QNA_DATE" :reply="slotProps.data.REPLY" :fileName="slotProps.data.FILE_NAME" :replyFileName="slotProps.data.REPLY_FILE_NAME" :qa="slotProps.data.QA_YN" :key="qnacKey"/>
                  </template>
                </DataTable>
                <br>
                <label @click="paging('prev')" class="link">
                  이전
                </label>
                <span style="margin:20px">{{page}}</span>
                <label @click="paging('next')" class="link">
                  다음
                </label>
              </div>
            </div>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </div>
  </div>
  <div v-else class="container_no_login card main"> 
    <span class="link" @click="this.$emit('login')">로그인해주세요</span>
  </div>
  <div class="ad-container">
    <Adsense
      data-ad-client="ca-pub-6699354447815429" 
      data-ad-slot="8230887469"
      data-ad-format="horizontal"
      data-full-width-responsive="true">
    </Adsense>
  </div>
  <Modal v-model="isShow" style="z-index: 99999999999;">
    <div class="modal" :key="modalKey">
      <span style="color:black; font-weight:bold;">주문내역</span>
      <div id="sub_menu_modal" style="margin-top:20px;margin-bottom:20px;" > <!-- 주문 -->
        <div v-if="orders.length === 0" class="no-result"> 
          주문내역이 없습니다.<br>
          아래의 "없음" 버튼을 눌러주세요!
        </div>
        <div v-else>
          <div v-for="(order, index) in orders" :key="index" >
            <button class="collapsible text-cut" style="text-align: center;">{{order.NAME}}</button>
            <orderc :key="ordercKey" :index="index" pageType="qna" @checkbox="checkbox" :checked="order.CHECKED" :idx="order.IDX" :invoiceNumber="order.INVOICE_NUMBER" :orderNumber="order.ORDER_NUMBER" :trackingNumber="order.TRACKING_NUMBER" :clearanceNumber="order.CLEARANCE_NUMBER" :orderDate='order.ORDER_DATE' :imgUrl='order.IMG_URL' :fprice='order.ORIGIN_PRICE' :wonPrice='order.SELL_PRICE' :url='order.URL' :status='order.STATUS' :country='order.COUNTRY' :deliveryPrice2='order.DELIVERY_PRICE2' :deliveryKrCompany='order.DELIVERY_KR_COMPANY' :deliveryForeignCompany='order.DELIVERY_FOREIGN_COMPANY'/>
          </div>
        </div>
      </div>
      <span>
        <div style="text-align: center;" v-if="orders.length !== 0">
          <label @click="orderPaging('prev')" class="link">
            이전
          </label>
          <span style="margin:20px">{{orderPage}}</span>
          <label @click="orderPaging('next')" class="link">
            다음
          </label>
          <br>
          <br>
          <div class="no-result" v-if="this.checkedMap.size === 0">문의하실 주문을 선택해주세요</div>
          <div class="no-result" v-else>선택 : {{ [...this.checkedMap.values()] }}</div>
        </div>
      </span>
      <br>
      <div class="div_center">
        <Button raised size="small" @click="click({type:'ok'})" label="선택"/>
        <Button raised size="small" @click="click({type:'empty'})" label="없음"/>
        <Button raised size="small" @click="closeModal()" label="닫기"/>
      </div>
    </div>
  </Modal>
</div>
</template>

<script>

import { ref, inject } from 'vue'
import orderc from '@/components/content/order_content.vue'
import qnac from '@/components/content/qna_content.vue'
import { FilterMatchMode } from '@primevue/core/api'
import restApi from './components/common/RestAPI'
import context from '@/components/common/Context.vue'
import policies from '@/components/common/policies.vue'
import ssr from '@/components/common/ssr'

export default {
  emits: ["setCookies", "setMenu", "login"],
  mounted() {
    this.click({type:'READ_FILE_ALL', filePath:`doc/policy.txt`, page:1, cntPerPage:10, keyword:this.policyKeyword})
    this.addJsonLd()
    this.api.setPage('qna')
    this.$emit("setMenu", {page:'qna'})
    console.log(this.api.getCookies('page'))    
    this.emitter.on('qnaReload', this.reload)

    const helper = this.$route.params.page
    if(helper !== undefined) {
      this.mIdx = '1'
      this.reload('dashboard')
    }
    window.scrollTo(0, 0)
  },
  unmounted() {
    this.emitter.off('qnaReload', this.reload)
  },
  components: {
    orderc, qnac, context, policies
  },
  setup() {
    const setMeta = inject('setMeta')
    const title = 'GEEK9 > 문의'
    const keywords = '문의,비용,수수료,주문,대행신청,빈티드,캐로셀,카루셀,카로셀,vinted,carousell,geek9,대행'
    const description = '주문/협업/견적 등 해외 업무와 관련해서 문의해주시면 GEEK9에서 해곃해드리겠습니다.'
    const geek9Url = ssr.GEEK9_URL + 'qna'
    setMeta({
      title: title,
      keywords: keywords,
      description: description,
      'og:title': title,
      'og:description': description,
      'og:image': 'https://geek9.kr/img/logo_big.e0186dc8.jpg',
      'og:url': geek9Url,
      'canonical': geek9Url
    })
    // useMeta( {
    //   title: 'GEEK9 > 문의',
    //   link: [{rel: "canonical", href: 'https://geek9.kr/qna'}],
    //   meta: [
    //     { vmid: 'charset_qna', charset: 'utf-8' },
    //     { vmid: 'http-equiv_qna', 'http-equiv': 'X-UA-Compatible', content: 'IE=edge,chrome=1' },
    //     { vmid: 'company_qna', name: 'company', itemprop: 'name', content: 'GEEK9' },
    //     { vmid: 'keywords_qna', name: 'keywords', content:'문의,비용,수수료,주문,대행신청,빈티드,캐로셀,카루셀,카로셀,vinted,carousell,geek9,대행' },
    //     { vmid: 'viewport_qna', name: 'viewport', content: 'width=device-width' },
    //     { vmid: 'description_qna', name: 'description', content: '주문/협업/견적 등 해외 업무와 관련해서 문의해주시면 GEEK9에서 해곃해드리겠습니다.' },
    //     { vmid: 'theme-color_qna', name: 'theme-color', content: '#ffa07a'},
    //     { vmid: 'og:site_name_qna', property: 'og:site_name', content: 'GEEK9'},
    //     { vmid: 'og:description_qna', property: 'og:description', content: '주문/협업/견적 등 해외 업무와 관련해서 문의해주시면 GEEK9에서 해곃해드리겠습니다.'},
    //     { vmid: 'og:title_qna', property: 'og:title', content: 'GEEK9 > 문의'},
    //     { vmid: 'og:image_qna', property: 'og:image', content: 'https://geek9.kr/img/logo_big.e0186dc8.jpg'},
    //     { vmid: 'og:url_qna', property: 'og:url', content: 'https://geek9.kr/qna'}
    //   ]
    // })
    const addJsonLd = () => {
      let flag = true
      document.querySelectorAll('script').forEach(el => {
        if (el.getAttribute('type') === 'application/ld+json' ) {
          flag = false
          return
        }
      })
      if(flag === true) {
        const script = document.createElement('script')
        script.setAttribute('type', 'application/ld+json')
        script.textContent = JSON.stringify(
          {
            "@context": "https://schema.org",
            "@graph": [
              ssr.getWebPage(geek9Url, title, ssr.GEEK9_URL, description),
            ]
          }
        )
        document.head.appendChild(script)
      }
    }
    const isShow = ref(false)
    let orders = ref([])
    const id = restApi.getCookies('id')
    async function showModal (orderListInput) {
      if(id === null || id === undefined || id === 'null') {
        alert('로그인해주세요.')
      } else {
        isShow.value = true
        await restApi.axiosGql('SELECT_ORDER_LIST2', {type:'SELECT_ORDER_LIST2', id: ""+id, page: 1})
        .then( (res) => {
          let orderList
          if(orderListInput.value !== undefined && orderListInput.value !== '') {
             orderList = orderListInput.value.split(',')
          }
          res.data.getOrderInfoById2.map(order => {
            let country = order.COUNTRY
            let currency = ' EUR'
            if(country === '홍콩') currency = ' HKD'
            else if(country === '영국') currency = ' 파운드'
            else if(country === '미국') currency = ' USD'
            else if(country === '일본') currency = ' 엔'
            else if(country === '중국') currency = ' 위안화'
            else if(country === '싱가포르') currency = ' SGD'
            else if(country ==='호주') currency = ' AUD'
            else if(country ==='뉴질랜드') currency = ' NZD'
            else if(country ==='캐나다') currency = ' CAD'
            else if(country === '기타') currency = ''
            
            order.ORIGIN_PRICE = order.ORIGIN_PRICE + currency
            let tmpStatus = order.STATUS

            if(tmpStatus !== undefined && tmpStatus > 0) order.STATUS = restApi.getOrderStatus(tmpStatus)

            let invoiceNumber = order.INVOICE_NUMBER
            let deliveryKrCompany = order.DELIVERY_KR_COMPANY

            if(invoiceNumber !== undefined && deliveryKrCompany !== undefined) order.INVOICE_NUMBER = restApi.getDeliveryUrl(invoiceNumber, deliveryKrCompany)
            if(orderList !== undefined && orderList.includes(''+order.IDX)) order.CHECKED = true
            else order.CHECKED = false
          })
          orders.value = res.data.getOrderInfoById2
        })
        .catch( (err) => {
          console.log(err)
        }) 
      }
    }
    function closeModal () {
      isShow.value = false
    }
    function onRowExpand (event) {
      expandedRows.value = {[event.data.IDX]:true}
    }
    const expandedRows = ref({})
    const cate = ref([
      { label: '게시판', route:'https://contents.geek9.kr' },
      { label: '문의', route: '/qna' }
    ])
    const loading = ref(true)
    const directInput = ref(false)
    const showInquiryForm = ref(false)
    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS }
    })
    return {
      addJsonLd, isShow, showModal, closeModal, cate, orders, expandedRows, onRowExpand, loading, filters, directInput, showInquiryForm
    }
  },
  data(){
    return{
      customStyle: {
        width: 'calc(100% - 40px)',
        '--placeholder-color': 'red'
      },
      mIdx : '0',
      policyKeyword:undefined,
      policyList:[],
      page: 1,
      orderPage: 1,
      orderOffset:5,
      checkedMap:new Map(),
      modalKey: 1,
      ordercKey:-1,
      qnaList: Array(),
      file: undefined,
      fileName: '',
      qnaKey:-1,
      qnacKey:-1,
      filteredCount:undefined
    }
  },
  methods: {
    onFilter(event) {
      this.filteredCount = event.filteredValue.length
    },
    onDirectInputChange() {
      if (this.directInput) {
        this.filters.global.value = "직접문의"
        this.showInquiryForm = true
      } else {
        this.filters.global.value = ""
        this.showInquiryForm = false
      }
    },
    
    onSearchInput() {
      if (!this.directInput) {
        if (this.filters.global.value !== "직접문의") {
          this.showInquiryForm = false
        }
      }
    },
    async readFile(event) {
      this.file = event.target.files[0]
      if(this.file.size >= 3072000) alert('3M 이하 파일만 첨부가 가능합니다')
      else {
        const type = 'UPLOAD_IMG'
        const formData = new FormData()
        formData.append("file", this.file)
        formData.append('service', 'geek9')
        formData.append('pbType', 'qna')
        formData.append('type', type)
        await this.api.axiosGql(type, formData)
        .then( async (res) => {
          this.fileName = res.data
          
          const preview = document.querySelector('#pic')
          preview.innerHTML = '첨부완료'
        })
      }  
    },
    async orderPaging(type) {
      if(type === 'prev') {
        if(this.orderPage === 1) {
          alert('첫 페이지입니다.')
          return
        } else this.orderPage -= 1
      } else this.orderPage += 1
      await this.clearChecked()
      await this.api.axiosGql('SELECT_ORDER_LIST2', {type:'SELECT_ORDER_LIST2', id: "" + this.api.getCookies('id'), page: this.orderPage})
      .then( (res) => {
        if(res.data.length === 0) {
          this.orderPage -= 1
          alert('마지막 페이지입니다.')
          return
        }
        let orderList
        res.data.getOrderInfoById2.map(order => {
          let country = order.COUNTRY
          let currency = ' EUR'
          if(country === '홍콩') currency = ' HKD'
          else if(country === '영국') currency = ' 파운드'
          else if(country === '미국') currency = ' USD'
          else if(country === '일본') currency = ' 엔'
          else if(country === '중국') currency = ' 위안화'
          else if(country === '싱가포르') currency = ' SGD'
          else if(country ==='호주') currency = ' AUD'
          else if(country ==='뉴질랜드') currency = ' NZD'
          else if(country ==='캐나다') currency = ' CAD'
          else if(country === '기타') currency = ''
          
          order.ORIGIN_PRICE = order.ORIGIN_PRICE + currency
          let tmpStatus = order.STATUS

          if(tmpStatus !== undefined && tmpStatus >= -1) order.STATUS = this.api.getOrderStatus(tmpStatus)

          let invoiceNumber = order.INVOICE_NUMBER
          let deliveryKrCompany = order.DELIVERY_KR_COMPANY

          if(invoiceNumber !== undefined && deliveryKrCompany !== undefined) order.INVOICE_NUMBER = this.api.getDeliveryUrl(invoiceNumber, deliveryKrCompany)
          if(orderList !== undefined && orderList.includes(''+order.IDX)) order.CHECKED = true
          else order.CHECKED = false

          const checkedMapValues = [...this.checkedMap.values()]

          if(checkedMapValues.includes(order.IDX)) order.CHECKED = true
          else order.CHECKED = false
        })
        this.orders = res.data.getOrderInfoById2
        this.ordercKey++
      })
      .catch( (err) => {
        console.log(err)
        if(type === 'prev') this.orderPage += 1
        else this.orderPage -= 1
      })
    },  
    paging(type) {
      if(type === 'prev') {
        if(this.page === 1) {
          alert('첫 페이지입니다.')
          return
        } else this.page -= 1
      } else this.page += 1
      this.getQna(this.page)
      .then( async (res) => {
        if(res.data.length === 0) {
          this.page -= 1
          alert('마지막 페이지입니다.')
        } else {
          this.qnaList = res.data
          for(var i = 0; i < this.qnaList.length; i++) {
            const json = this.qnaList[i]
            this.qnaList[i].TITLE = String(json.CONTENT).substring(0, 40) + '...'
            this.qnaList[i].QNA_DATE = this.api.getDateByFormat(this.qnaList[i].QNA_DATE, 'YYYY.MM.DD')
            if(json.REPLY === null || json.REPLY === undefined || json.REPLY === '') this.qnaList[i].REPLY_YN = "답변전"
            else this.qnaList[i].REPLY_YN = "답변완료"
          }
          this.qnacKey++
        }
      }).catch( (err) => {
        console.log(err)
        if(type === 'prev') this.page += 1
        else this.page -= 1
      })
    },
    checkbox(param) {
      let checkedSeq = Number(param.seq)
      let mapIdx = (this.orderPage) * this.orderOffset + checkedSeq - this.orderOffset
      const checkedIdx = Number(param.idx)

      let check = document.querySelectorAll('span.check input')[checkedSeq]
      if(check.checked) this.checkedMap.set(mapIdx, checkedIdx)
      else this.checkedMap.delete(mapIdx)
    },
    async clearChecked() {
      let chkList = document.querySelectorAll('span.check input')
      for(var i=0; i<chkList.length; i++) {
        chkList[i].checked = false
      }
    },
    async getQna(page) {
      return this.api.axiosGql('SELECT_QNA_LIST', {type:'SELECT_QNA_LIST', id: ""+this.api.getCookies('id'), page: page})
    },
    getCookies(key) {
      return this.api.getCookies(key)
    },
    validate(type, target) {
      this.api.validate(type, target)
    },
    async procGetQna(page) {
      this.getQna(page)
      .then( async (res) => {
        this.qnaList = res.data
        for(var i = 0; i < this.qnaList.length; i++) {
          const json = this.qnaList[i]
          this.qnaList[i].TITLE = String(json.CONTENT).substring(0, 40) + '...'
          this.qnaList[i].QNA_DATE = this.api.getDateByFormat(this.qnaList[i].QNA_DATE, 'YYYY.MM.DD')
          if(json.REPLY === null || json.REPLY === undefined || json.REPLY === '') this.qnaList[i].REPLY_YN = "N"
          else this.qnaList[i].REPLY_YN = "Y"
        }
        this.qnacKey++
      })
    },
    async select(event) {
      if(event.target.value === '1') {
        this.$refs.picName.innerHTML = ''
        const preview = document.querySelector('#pic')
        preview.src = ''
      } else this.reload('dashboard')
    },
    async click(param) {
      const type = param.type
      if(type === 'showModal') {
        if(this.$refs.order.value === '' || this.$refs.order.value === '없음') this.checkedMap.clear()
        
        this.clearChecked()
        this.orderPage = 1
        this.showModal(this.$refs.order)
      } else if(type === 'READ_FILE_ALL') {
        this.policyList = []
        this.api.axiosGql(type, param)
        .then( async (res) => {
          if(res.status === 200) {
            const tmeResult = res.data
            this.policyList = tmeResult
            .filter(item => item !== '' && item !== undefined)
            .map((item, index) => ({ index: index + 1, content: item }));
          } else alert('다시 시도해주세요')
        })
        .catch( (err) => {
          console.log(err)
          alert('다시 시도해주세요.')
        }).finally( ()=> {
          this.loading = false
        })
      } else if(type === 'ok') {
        let res= ''
        for(let [, value] of this.checkedMap) {
          if(res === '') res = value
          else res += ',' + value
        }
        if(res === '' ) {
          alert('문의할 주문의 idx를 선택해주세요.')
          return
        }
        this.$refs.order.value = res
        this.closeModal()
      } else if(type === 'empty') {
        this.$refs.order.value = '없음'
        this.closeModal()
      } else if(type === 'reg') {
        let id = this.api.getCookies('id')
        if(id === undefined || id == null) alert('로그인해주세요.')
        else {
          const nameInput = this.$refs.name
          const mailInput = this.$refs.mail
          const contentInput = this.$refs.content
          const orderInput = this.$refs.order
  
          if(nameInput.value === undefined || nameInput.value === '') {
            alert('성함을 입력해주세요')
            nameInput.focus()
          } else if(mailInput.value === undefined || mailInput.value === '') {
            alert('이메일을 입력해주세요')
            mailInput.focus()
          } else if(contentInput.value === undefined || contentInput.value === '') {
            alert('문의내용을 입력해주세요')
            contentInput.focus()
          } else if(orderInput.value === undefined || orderInput.value === '') {
            alert('주문내역을 선택해주세요')
            orderInput.focus()
          } else {
            if(confirm('문의하시겠습니까?')) {
              this.emitter.emit('dialog', {flag:true, title:'등록중입니다.'})
              await this.api.axiosGql('INSERT_QNA', {type:'INSERT_QNA', id:id, orderList:this.$refs.order.value, content:contentInput.value, phone:this.$refs.phone.value, mail:mailInput.value, orderName: nameInput.value, fileName:this.fileName})
              .then( async (res) => {
                if(res.status === 200) {
                  nameInput.value = ''
                  mailInput.value = ''
                  contentInput.value = ''
                  this.$refs.order.value = ''
                  this.$refs.phone.value = ''
                  this.emitter.emit('dialog', {flag:false})
                  alert('문의가 정상적으로 등록되었습니다.')
                  const el = document.getElementById('qna-id')
                  el.scrollIntoView(el.scrollTop)
                  this.mIdx = '1'
                  this.procGetQna(this.page)
                  this.qnaKey++
                } else {
                  this.emitter.emit('dialog', {flag:false})
                  alert('다시 시도해주세요.')
                }
              })
              .catch( (err) => {
                console.log(err)
                this.emitter.emit('dialog', {flag:false})
                alert('다시 시도해주세요.')
              })
            }
          }
        }
      }
    },
    collapse(sub_idx) {

      const sub_menu_list = document.getElementById("sub_menu")
      let col_btn_list = sub_menu_list.getElementsByTagName("button")
      let col_con_list = sub_menu_list.getElementsByClassName("content")
      
      const btn = col_btn_list[sub_idx]
      const con = col_con_list[sub_idx]
      
      if(btn.getAttribute("class") === 'collapsible select' || btn.getAttribute("class") === 'collapsible select text-cut') {
        btn.setAttribute("class", "collapsible text-cut")
        btn.style.color = "black"
        btn.style.fontWeight = ""
        con.style.maxHeight = null
      } else {
        btn.setAttribute("class", "collapsible select text-cut")
        btn.style.fontWeight = "bold"
        con.style.maxHeight = con.scrollHeight + "px"
      }
    },
    reload(flag) {
      if(flag === 'dashboard') {
        if(this.api.getCookies('login') === 'true' || this.api.getCookies('login') === true) this.procGetQna(this.page)
        this.qnaKey++
        this.$emit("setMenu", {page:'qna'})
        this.addJsonLd()
      }
    },
    async remove(json) {
      if(confirm("삭제할까요?")) {
        await this.api.axiosGql('DELETE_QNA', {type:'DELETE_QNA', idx:json.idx, id:this.api.getCookies('id'), fileName:json.fileName, replyFileName:json.replyFileName})
        .then( async () => {
          this.procGetQna(this.page)
          alert('삭제되었습니다.')
          this.expandedRows = {}
          this.mIdx = '1'
          const el = document.getElementById('qna-id')
          el.scrollIntoView(el.scrollTop)
        })
        .catch( (err) => {
          console.log(err)
          alert('다시 시도해주세요.')
          this.$notify('다시 시도해주세요.')
        })      
      }
    },
  }
}
</script>

<style scope>

textarea {
  width: 100%;
  height: 200px;
  resize: none;
}
.input {
  max-width: 100px;
  float: right;
}
.td_title select {
  width: 100%;
  text-align: center;
  padding: 10px;
  font-weight: bold;
  border: 1px solid lightsalmon;
}
.navbar {
  width: 100%;
  background-color: white;
  overflow: auto;
  cursor: pointer;
  background-color: lightgoldenrodyellow;
  border-radius: 9px;
}
.navbar span {
  padding-top: 5px;
  padding-bottom: 5px;
  float: left;
  color: black;
  text-align: center;
  border-radius: 9px;
}
.navbar span.active {
  background-color: lightsalmon;
  color: white;
  font-weight: bold;
}
@media screen and (max-width: 50px) {
  .navbar span {
    float: none;
    display: block;
    width: 100%;
    text-align: left;
  }
}
.collapsible {
  background-color: lightgoldenrodyellow;
  color: black;
  cursor: pointer;
  margin-right: 10px;
  margin-bottom: 5px;
  padding: 5px;
  border-radius: 9px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
}
.select, .collapsible:hover {
  background-color: #f1f1f1;
  border-radius: 9px;
}
.collapsible:after {
  color: black;
  font-weight: bold;
  float: left;
  color: red;
}
.select:after {
  font-weight: bold;
}
.collapsible-font {
  color:red;
  float: left;
  font-size: 14px;
  vertical-align: middle;
  align-content: center; 
  font-weight: bold;
  margin-right: 10px;
  text-align: center;
}
.reply-yn-font {
  color:red;
  font-size: 8px;
  vertical-align: middle;
  margin-right: 10px;
  margin-left: 10px;
  text-align: center;
}
.content {
  max-height: 0;
  height: auto;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  background-color: rgb(250, 250, 250);
}
.attach_label {
  float:right;
  border: 0px;
  padding:5px;
}
.attach {
  float:right;
  border-radius: 9px;
  font-style: italic;
  padding:5px;
  max-width: 70px;
  cursor: pointer;
}
#q1, #q2 {
  width:10px;
  margin-left:20px;
}
.p-inputtext::placeholder {
  color: var(--placeholder-color, inherit) !important;
}
</style>
